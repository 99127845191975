import React from 'react';

/* Material UI components */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Avatar, MenuItem, Collapse, Divider, Radio, RadioGroup, FormLabel, CssBaseline, Typography, Container, Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

/* Styling */
const MainWindow = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const LogoAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'center'
}));

const RootDiv = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(3),
    justifyContent: 'center'
}));

const TOS = 
`本公司致力遵守《個人資料(私隱)條例》(香港法例第486章)(“條例”)之規定，維護本公司網站使用者 (統稱“使用者” 或 “閣下”) 所提供足以識別個人身份之資料 (“個人資料”)。我們列出公司的私隱及個人資料收集政策如下。

個人資料的收集將按公司的《個人資料收集聲明》的條款所進行。個人資料將會安全穩妥儲存於公司的系統內，而公司將採取所有切實可行的步驟以確保個人資料的保存時間不超過將其保存以貫徹該個人資料被使用的目的(包括任何直接有關的目的)所需的時間。除公司的《個人資料收集聲明》內所載之人士外，公司不會向任何公司以外的人士發佈該等個人資料。閣下有權根據載於公司的《個人資料收集聲明》的程序，要求存取及更正其個人資料。

個人資料收集聲明

本《個人資料收集聲明》列出收集個人資料的目的、使用者同意公司如何使用該等資料及使用者根據條例享有的權利。每當閣下登入我們網站或使用公司之產品或服務時，即表示同意接受當時生效之個人資料收集聲明。

個人資料之收集

使用者可能會被要求提供以下的個人資料:
個人資料如姓名、年齡、電話號碼、入帳戶口編號、戶口持有人姓名。我們亦會收集不足以識別個人身份之資料，例如問卷調查的回應等。

為向閣下提供各項產品或服務，公司需要收集個人資料。閣下沒有責任必須提供個人資料，惟若閣下選擇不提供個人資料，有關的產品或服務或未能提供予閣下。

收集個人資料之目的及用途

公司所收集之個人資料將用作向閣下發放完成問卷後的報酬之用。

查閱個人資料及其他權利

經我們核實閣下身份後，閣下有權根據條例的規定要求查閱或修改公司所保存關於閣下的個人資料。根據條例的規定，公司有權就處理閣下的查詢收取合理費用。

如閣下需要查閱，修改個人資料，或索取公司的私隱及個人資料收集政策，請電郵至：hello@mindlayer.io`
class RegistrationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTOS: false,
            isLoading: false,

            /* Registration Form */
            isTOSAgreed: false,
            fullName: "",
            age: "",
            phoneNumber: "",
            paymentMethod: "eCheque"
        }
        this.handleTOSCheckboxChange = this.handleTOSCheckboxChange.bind(this);
        this.handleInfoChange = this.handleInfoChange.bind(this);
        this.doRegistration = this.doRegistration.bind(this);
        this.checkForm = this.checkForm.bind(this);
    }

    handleTOSCheckboxChange(event, checked) {
        this.setState({
            isTOSAgreed: checked
        })
    }

    handleInfoChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    
    checkForm(){
        if(this.state.fullName === ""){
            alert("Please fill in your name.");
        }
        else if(this.state.age === ""){
            alert("Please fill in your age.");
        }
        else if(this.state.phoneNumber === ""){
            alert("Please fill in your phone number.");
        }else{
            this.setState({showTOS: true})
        }
    }
    async doRegistration(){
        this.setState({
            isLoading: true
        })

        let userDoc = {
            CompletedJobs: [],
            Name: this.state.fullName,
            Age: parseInt(this.state.age),
            Phone: parseInt(this.state.phoneNumber),
            Email: this.props.firebase.auth().currentUser.email,
            PaymentMethod: "e-Cheque"
        };

        await this.props.firebase.firestore().collection("Users").doc(this.props.firebase.auth().currentUser.uid).set(userDoc);
        await this.props.firebase.firestore().collection("Answers").doc(this.props.firebase.auth().currentUser.uid).set({}, {merge: true});

        this.setState({
            isLoading: false
        });
        
        this.props.finishRegistration();
    
    }

    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <MainWindow>
                    <LogoAvatar>
                        <LockOutlinedIcon />
                    </LogoAvatar>
                    <Typography align="center" component="h1" variant="h5">
                        Registration
                </Typography>
                    <RootDiv>
                        <Grid container spacing={2} justify="center" >
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Full Name"
                                    autoFocus
                                    required
                                    value={this.state.fullName}
                                    onChange={this.handleInfoChange('fullName')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Age"
                                    required
                                    fullWidth
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={this.state.age}
                                    onChange={this.handleInfoChange('age')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    required
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleInfoChange('phoneNumber')}
                                    helperText="For contacting you"    
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend">Payment Method</FormLabel>
                                <RadioGroup onChange={this.handleInfoChange('paymentMethod')} value={this.state.paymentMethod}>    
                                    <FormControlLabel
                                        value="transfer"
s                                        control={<Radio checked color="primary" style={{ width: 'auto' }}/>}
                                        label="eCheque"
                                        labelPlacement="end"
                                    />
                                    <Typography paragraph style={{color: "grey", fontSize: "0.8rem"}}>
                                        An eCheque shall be issued to the sign-up email within 2 weeks after you complete the task.
                                    </Typography>
                                    <Typography paragraph style={{color: "grey", fontSize: "0.8rem"}}>
                                        *Candidate who have finished all the questions before the deadline will receive the payment within 2 weeks. Payment for late submission shall be postponed accordingly.
                                    </Typography>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <br />
                        <Divider />
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    disabled={this.state.isLoading}
                                    onClick={this.checkForm}
                                >
                                    Register
                                </Button>
                            </Grid>
                    </RootDiv>
                </MainWindow>
                <Dialog
                    open={this.state.showTOS}
                    fullWidth={true}
                    onClose={()=>{this.setState({showTOS: false})}}
                >
                    <DialogTitle id="alert-dialog-slide-title">Terms of Services</DialogTitle>
                    <DialogContent>
                        <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}> 
                            {TOS}
                        </pre>
                    </DialogContent>
                    <StyledDialogActions>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this.doRegistration}
                        >
                            I agree to the TOS above
                    </Button>
                    </StyledDialogActions>
                </Dialog>
            </Container>
        )
    }
}

export default RegistrationPage;