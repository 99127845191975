import React from 'react';
import Recorder from 'recorder-js';

/* Material UI components */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MicIcon from '@material-ui/icons/Mic';
import {red, green} from '@material-ui/core/colors';
import { CircularProgress, Avatar, Button, CssBaseline, Typography, Container, Paper, MobileStepper, TextField } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { styled } from '@material-ui/core/styles';

/* Styling */
const MainWindow = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const LogoAvatar = styled(Avatar)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}));

const RootDiv = styled('div')(({theme}) => ({
    width: '100%',
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
}));

const StoppedRecordButton = styled(MicIcon)(({theme}) => ({
    color: green[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    fontSize: 200
}));
  
const StartedRecordButton = styled(MicIcon)(({theme}) => ({
    color: red[600],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    fontSize: 200
}));

const NormalRecordButton = styled(MicIcon)(({theme}) => ({
    color: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    fontSize: 200
}));

const HeaderPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    //height: 400,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));
  
const MarginOnlyDiv = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    position: 'relative'
}));

const TextInputField = styled(TextField)(({theme}) => ({
    width: '95%',
    margin: '4px'
}));

function ProcessingRecordButton(){
    return (
        <div style={{position: 'relative'}}>
            <NormalRecordButton /><br />
            <CircularProgress size={24} />
        </div>
    )
}

class JobPage extends React.Component{
    constructor(props){
        super(props);

        this.state={
            isRecording: false,
            isRecordingButtonDisabled: false,
            currentQuestionIndex: 0,
            currentQuestionId: this.props.jobQuestionIds[0],

            textInput: [],
            labelInput: "",
            transcriptInput: "",
            isInputSet: false,
            isSaving: false,
            
        }

        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.dontGotStream = this.dontGotStream.bind(this);
        this.allTextFieldsFilled = this.allTextFieldsFilled.bind(this);
        this.labelFilled = this.labelFilled.bind(this);
        this.transcriptFilled = this.transcriptFilled.bind(this);
        this.isContainRecordingQuestion = this.isContainRecordingQuestion.bind(this);
    }



    isContainRecordingQuestion(){
        for(let questionId of this.props.jobQuestionIds){
            if(this.props.jobQuestions[questionId]['QuestionType'] === "recording"){
                return true;
            }
        }
        return false;
    }

    componentWillMount(){
        let answerCount = Object.keys(this.props.jobAnswers).length;
        this.setState({
            currentQuestionIndex: answerCount,
            currentQuestionId: this.props.jobQuestionIds[answerCount]
        });

        if(this.isContainRecordingQuestion()){

            let audioContext = window.AudioContext || window.webkitAudioContext || false;
            if(audioContext){
                this.audioContext = new audioContext();
                //this.audioContext.suspend();
            }else{
                alert("This browser is not supported. Please try with another browser");
                this.props.returnHome();
                return;
            }
            this.recorder = new Recorder(this.audioContext,{});
            if(navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia({audio: true, video: false})
                .then((stream) => {
                    this.stream = stream;
                    this.recorder.init(stream);
                })
                .catch(this.dontGotStream);
            }else{
                alert("This browser is not supported. Please try with another browser.");
                this.props.returnHome();
                return;
            }
        }
    }

    componentWillUnmount(){
        
        if(this.isContainRecordingQuestion()){
            try{
                this.stream.getTracks().forEach(track => {track.stop()});
                this.audioContext.close();
                this.recorder = null;
            }
            catch(err){
                console.log(err);
            }   
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!this.state.isInputSet && this.state.currentQuestionIndex !== this.props.jobQuestionIds.length){
            if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "text"){
                if(this.props.jobAnswers[this.state.currentQuestionId]){
                    this.setState({
                        isInputSet: true,
                        textInput: this.props.jobAnswers[this.state.currentQuestionId]['Answer']
                    })
                }else{
                    let textInput = [];
                    for(let i=0; i<this.props.jobQuestions[this.state.currentQuestionId]['QuestionCount']; i++){
                        textInput.push("");
                    }
                    this.setState({
                        isInputSet: true,
                        textInput: textInput
                    })
                }
            }

            if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "label"){
                if(this.props.jobAnswers[this.state.currentQuestionId]){
                    this.setState({
                        isInputSet: true,
                        labelInput: this.props.jobAnswers[this.state.currentQuestionId]['Answer'][0]
                    })
                }else{
                    this.setState({
                        isInputSet: true,
                        labelInput: ""
                    })
                }
            }

            if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "transcript"){
                if(this.props.jobAnswers[this.state.currentQuestionId]){
                    this.setState({
                        isInputSet: true,
                        transcriptInput: this.props.jobAnswers[this.state.currentQuestionId]['Answer'][0]
                    })
                }else{
                    this.setState({
                        isInputSet: true,
                        transcriptInput: ""
                    })
                }
            }
        }
    }

    dontGotStream(error) {
        alert("This platform does not work on this browser. Please try with another browser.");
        this.props.returnHome();
    }

    startRecording(){
        console.log('Start Recording');
        this.setState({isRecordingButtonDisabled: true},()=>{
            if(this.audioContext.state !== 'running'){
                this.audioContext.resume().then(()=>{console.log("Resume audio context"); this.recorder.start().then(()=>{this.setState({isRecording: true, isRecordingButtonDisabled: false})})});
            }else{
                this.recorder.start().then(()=>{this.setState({isRecording: true, isRecordingButtonDisabled: false})});
            }
            
        });
    }
    
    stopRecording(){
        console.log('Stop Recording');

        this.setState({
          isRecordingButtonDisabled: true
        });

        this.recorder.stop().then(async ({blob}) => {

            if(this.audioContext.state === 'running'){
                console.log("Suspend audio context");
                //this.audioContext.suspend();
            }

            if(blob.size <= 44){
                this.setState({
                    isRecording: false,
                    isRecordingButtonDisabled: false
                });
                return alert("It seems that there is an error with recording. You may want to reload the page and try again.");
            }
            let storageRef = this.props.firebase.storage().ref().child(this.props.firebase.auth().currentUser.uid);
            let fileRef = storageRef.child(this.state.currentQuestionId + ".wav");
            let result
            try{
                result = await fileRef.put(blob);
            }
            catch(err){
                this.setState({
                    isRecording: false,
                    isRecordingButtonDisabled: false
                });
                return alert("Failed to upload the audio file. Please try again.");
            }
            
            let metadata = result['metadata'];
            let path = metadata['fullPath'];
            const timestamp = Math.floor(Date.now() / 1000);

            let db = this.props.firebase.firestore();
            try{
                await db.collection("Answers").doc(this.props.firebase.auth().currentUser.uid).update(`${this.props.selectedJob}.${this.state.currentQuestionId}`, 
                    {
                        Type: "recording",
                        Answer: [path],
                        Timestamp: timestamp
                    }
                );
            }
            catch(err){
                this.setState({
                    isRecording: false,
                    isRecordingButtonDisabled: false
                });
                return alert("Failed to save. Try again.")
            }

            let jobAnswers = this.props.jobAnswers;
            jobAnswers[this.state.currentQuestionId] = {
              Type: "recording",
              Answer: [path],
              Timestamp: timestamp
            }
            this.props.updateJobAnswers(jobAnswers);

            /*
            let jobAnswerUrls = this.props.jobAnswerUrls;
            try{
                jobAnswerUrls[this.state.currentQuestionId] = await fileRef.getDownloadURL();
            }
            catch(err){
                jobAnswerUrls[this.state.currentQuestionId] = "error";
                alert("It failed to retrieve a playback but you can still proceed to the next question.")
            }
            this.props.updateJobAnswerUrls(jobAnswerUrls)
            */
            
            this.setState({
                isRecording: false,
                isRecordingButtonDisabled: false
            });
        });
    }

    allTextFieldsFilled(){
       let textInput = this.state.textInput;
        for(let i=0; i<this.props.jobQuestions[this.state.currentQuestionId]['QuestionCount']; i++){
            if( !Boolean(textInput[i])){
                return false;
            }
        }
        return true;
    }

    labelFilled(){
        return (this.state.labelInput !== "" && this.state.labelInput && this.state.labelInput !== undefined);
    }

    transcriptFilled(){
        return (this.state.transcriptInput && this.state.transcriptInput !== "");
    }

    render(){
        let stepContent = [];
        let answerWidget = [];
        let questionWidget = [];
        let playbackDiv;
        if(this.state.currentQuestionIndex !== this.props.jobQuestionIds.length){
            if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "recording"){
                let recordingButton;
                if(this.state.isRecordingButtonDisabled){
                    recordingButton = <ProcessingRecordButton />
                }else{
                    if(this.state.isRecording){
                        recordingButton = <StartedRecordButton onClick={this.stopRecording}/>
                    }else{
                        recordingButton = <StoppedRecordButton onClick={this.startRecording}/>
                    }
                }

                if(this.props.jobAnswers[this.state.currentQuestionId] && this.state.isRecording === false && this.state.isRecordingButtonDisabled === false){
                    playbackDiv = <WavAudioFrame key={this.state.currentQuestionId + Math.floor(Date.now() / 1000).toString()} firebase={this.props.firebase} url={this.props.jobAnswers[this.state.currentQuestionId]['Answer'][0]} relativePath={true} />
                }

                answerWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Typography align="center" component={'span'}>{recordingButton}</Typography></div>
                )
                answerWidget.push(
                    playbackDiv
                )

                questionWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Typography align="center" component="h1" variant="h5">Q: {this.props.jobQuestions[this.state.currentQuestionId]['Question']}</Typography></div>
                )
            }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "text"){
                let answerBlanks = [];
                for(let i=0; i<this.props.jobQuestions[this.state.currentQuestionId]['QuestionCount']; i++){
                    let labelName = "Answer " + (i+1);
                    let keyName = this.state.currentQuestionId + "_" + i;
                    answerBlanks.push(
                        <TextInputField
                            label={labelName}
                            value={this.state.textInput[i] || ""}
                            onChange={(event)=>{
                                let textInput = this.state.textInput;
                                textInput[i] = event.target.value;
                                this.setState({
                                    textInput: textInput
                                })
                            }}
                            InputLabelProps={{ shrink: true }}
                            key={keyName}
                            onPaste={(e)=>{
                                e.preventDefault();
                                return false;
                            }}
                        />
                    );
                }
                answerWidget.push(
                    <div key="ThisIsAnEasterEgg" style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Typography align="center" component={'span'}><br />{answerBlanks}<br />Answers will only be saved when you click "Next".</Typography></div>
                )
                questionWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Typography align="center" component="h1" variant="h5">Q: {this.props.jobQuestions[this.state.currentQuestionId]['Question']}</Typography></div>
                )
            }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "label"){
                let buttons = [];
                for(let i=0; i<this.props.jobQuestions[this.state.currentQuestionId]['QuestionOptions'].length; i++){
                    buttons.push(
                        <ToggleButton value={this.props.jobQuestions[this.state.currentQuestionId]['QuestionOptions'][i]}>
                            {this.props.jobQuestions[this.state.currentQuestionId]['QuestionOptions'][i]}
                        </ToggleButton>
                    )
                }

                answerWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6px'}}><Typography align="center" component={'span'}><br /></Typography><br /> <br />
                        <ToggleButtonGroup size="medium" value={this.state.labelInput} exclusive onChange={(event, value)=>{this.setState({labelInput: value})}}>
                            {buttons}
                        </ToggleButtonGroup>
                    </div>
                )
                answerWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6px'}}><Typography align="center" component={'span'}>Answers will only be saved when you click "Next".</Typography></div>
                )
                questionWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Typography align="center" component="h1" variant="h5">Q: {this.props.jobQuestions[this.state.currentQuestionId]['Question']}</Typography></div>
                )
            }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "transcript"){
                answerWidget.push(
                    <div key="ThisIsAnEasterEgg" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Typography align="center" component={'span'}><br />
                            <TextInputField
                                multiline
                                label="Answer"
                                value={this.state.transcriptInput}
                                onChange={(event)=>{
                                    this.setState({
                                        transcriptInput: event.target.value
                                    })
                                }}
                                InputLabelProps={{ shrink: true }}
                                onPaste={(e)=>{
                                    e.preventDefault();
                                    return false;
                                }}
                            />
                            <br />輸入完畢後，請按”NEXT”到下一條，如果錄音內容唔清楚或是雜音，可以在輸入一欄中打”skip”跳過。
                        </Typography>
                    </div>
                    
                )
                questionWidget.push(
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Typography align="center" component="h1" variant="h5">
                            請將錄音一字不漏轉化成文字 <br />
                            <WavAudioFrame key={this.state.currentQuestionId} firebase={this.props.firebase} url={this.props.jobQuestions[this.state.currentQuestionId]['QuestionPath']}/>
                        </Typography>
                    </div>
                )
            }
            

            stepContent.push(
            <HeaderPaper square evevation={0} key="JustARandomKey">
                <MarginOnlyDiv>
                    
                    {questionWidget}
                    {answerWidget}                    
                    
                </MarginOnlyDiv>
            </HeaderPaper>
            );
        }else{
            stepContent.push(
            <HeaderPaper key="AnotherRandomKey" square evevation={0}>
                <MarginOnlyDiv>
                <Typography align="center" component="h1" variant="h4">You have finished all questions in this job.</Typography>
                </MarginOnlyDiv>
            </HeaderPaper>
            );
        }

        let nextButtonDisabled;
        if(this.state.currentQuestionIndex === (this.props.jobQuestionIds.length)){
            nextButtonDisabled = true;
        }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "recording"){
            nextButtonDisabled = (!this.props.jobAnswers[this.state.currentQuestionId] || this.state.isRecordingButtonDisabled);
        }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "text"){
            nextButtonDisabled = !this.allTextFieldsFilled() || this.state.isSaving;
        }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "label"){
            nextButtonDisabled = !this.labelFilled() || this.state.isSaving;
        }else if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "transcript"){
            nextButtonDisabled = !this.transcriptFilled() || this.state.isSaving;
        }
            
        
        return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MainWindow>
                <LogoAvatar>
                    <LockOutlinedIcon />
                </LogoAvatar>
                <RootDiv>
                    <StyledPaper>
                        {stepContent}
                        <MobileStepper
                        variant="progress"
                        steps={this.props.jobQuestionIds.length +1}
                        position="static"
                        activeStep={this.state.currentQuestionIndex}
                        nextButton={
                            <Button 
                            style={{width: 95.73}}
                            size="small"
                            onClick={async ()=>{
                                if(this.state.currentQuestionIndex === this.props.jobQuestionIds.length -1){
                                    this.props.markJobCompleted();
                                }

                                if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "text"){
                                    this.setState({
                                        isSaving: true
                                    });

                                    const timestamp = Math.floor(Date.now() / 1000);
                                    let db = this.props.firebase.firestore();
                                    try{
                                        await db.collection("Answers").doc(this.props.firebase.auth().currentUser.uid).update(`${this.props.selectedJob}.${this.state.currentQuestionId}`, 
                                            {
                                                Type: "text",
                                                Answer: this.state.textInput,
                                                Timestamp: timestamp
                                            }
                                        );
                                    }
                                    catch(err){
                                        this.setState({isSaving: false});
                                        return alert("Failed to save. Try again.");
                                    }

                                    let jobAnswers = this.props.jobAnswers;
                                    jobAnswers[this.state.currentQuestionId] = {
                                        Type: "text",
                                        Answer: this.state.textInput,
                                        Timestamp: timestamp
                                    }
                                    this.props.updateJobAnswers(jobAnswers);
                                }

                                if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "label"){
                                    this.setState({
                                        isSaving: true
                                    });

                                    const timestamp = Math.floor(Date.now() / 1000);
                                    let db = this.props.firebase.firestore();
                                    try{
                                        await db.collection("Answers").doc(this.props.firebase.auth().currentUser.uid).update(`${this.props.selectedJob}.${this.state.currentQuestionId}`, 
                                            {
                                                Type: "label",
                                                Answer: [this.state.labelInput],
                                                Timestamp: timestamp
                                            }
                                        );
                                    }
                                    catch(err){
                                        this.setState({isSaving: false});
                                        return alert("Failed to save. Try again.");
                                    }

                                    let jobAnswers = this.props.jobAnswers;
                                    jobAnswers[this.state.currentQuestionId] = {
                                        Type: "label",
                                        Answer: [this.state.labelInput],
                                        Timestamp: timestamp
                                    }
                                    this.props.updateJobAnswers(jobAnswers);                        
                                }

                                if(this.props.jobQuestions[this.state.currentQuestionId]['QuestionType'] === "transcript"){
                                    this.setState({
                                        isSaving: true
                                    });
                                    
                                    const timestamp = Math.floor(Date.now() / 1000);
                                    let db = this.props.firebase.firestore();
                                    try{
                                        await db.collection("Answers").doc(this.props.firebase.auth().currentUser.uid).update(`${this.props.selectedJob}.${this.state.currentQuestionId}`, 
                                            {
                                                Type: "transcript",
                                                Answer: [this.state.transcriptInput],
                                                Timestamp: timestamp
                                            }
                                        );
                                    }
                                    catch(err){
                                        this.setState({isSaving: false});
                                        return alert("Failed to save. Try again.");
                                    }

                                    let jobAnswers = this.props.jobAnswers;
                                    jobAnswers[this.state.currentQuestionId] = {
                                        Type: "transcript",
                                        Answer: [this.state.transcriptInput],
                                        Timestamp: timestamp
                                    }
                                    this.props.updateJobAnswers(jobAnswers);                        
                                }

                                this.setState({currentQuestionIndex: parseInt(this.state.currentQuestionIndex)+1, currentQuestionId: this.props.jobQuestionIds[parseInt(this.state.currentQuestionIndex)+1], isInputSet: false, isSaving: false});
                            }}
                            disabled={nextButtonDisabled}
                            >
                            {this.state.isSaving? "Saving..":"Next"}
                            <KeyboardArrowRightIcon />
                            </Button>
                        }
                        backButton={
                            <Button 
                            style={{width: 95.73}}
                            size="small"
                            onClick={()=>{
                                this.setState({currentQuestionIndex: parseInt(this.state.currentQuestionIndex)-1, currentQuestionId: this.props.jobQuestionIds[parseInt(this.state.currentQuestionIndex)-1], isInputSet: false});
                            }}
                            disabled={this.state.currentQuestionIndex === 0 || this.state.isSaving}
                            >
                            Back
                            <KeyboardArrowLeftIcon />
                            </Button>
                        }
                        />
                    </StyledPaper>
                    <Typography align="center">
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={()=>{
                                this.props.returnHome();
                            }}
                            disabled={this.props.disableReturnHomeButton}
                        >
                            Return Home
                        </Button>
                    </Typography>
                </RootDiv>
            </MainWindow>
            </Container>
        )
    }
}

class WavAudioFrame extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            url: '',
            isError: false,
        }
        this.reload = this.reload.bind(this);
    }

    componentWillMount(){
        if(this.props.relativePath){
            let fileRef = this.props.firebase.storage().ref(this.props.url);
            fileRef.getDownloadURL().then((url) => {
                this.setState({ 
                    url
                }, ()=>{
                    this.setState({
                        isError: true
                    })
                });
            });
        }else{
            let fileRef = this.props.firebase.storage().refFromURL(this.props.url);
            fileRef.getDownloadURL().then((url) => {
                this.setState({ 
                    url
                });
            }, ()=>{
                this.setState({
                    isError: true
                })
            });
        }
        
    }

    reload(){
        this.setState({
            url: '',
            isError: false,
        });

        if(this.props.relativePath){
            let fileRef = this.props.firebase.storage().ref(this.props.url);
            fileRef.getDownloadURL().then((url) => {
                this.setState({ 
                    url
                });
            }, ()=>{
                this.setState({
                    isError: true
                })
            });
        }else{
            let fileRef = this.props.firebase.storage().refFromURL(this.props.url);
            fileRef.getDownloadURL().then((url) => {
                this.setState({ 
                    url
                });
            }, ()=>{
                this.setState({
                    isError: true
                })
            });
        }
    }

    render(){
        if(this.state.url){
            return(
                <audio style={{width: '100%'}}  controls src={this.state.url} type="audio/wav" controlsList="nodownload"/>
            )
        }if (this.state.isError){
            return (
                <div onClick={()=>{this.reload();}}>Failed to load the playback. </div>
            )
        }else{
            return(
                <div onClick={()=>{this.reload();}}>Loading playback. </div>
            );
        }
    }
}

export default JobPage;