import React from 'react';
import './App.css';

/* Firebase and Firebase Auth */
import firebase from 'firebase';

/* Import other pages */
import SignInPage from './SignInPage';
import JobListPage from './JobListPage';
import JobPage from './JobPage';
import RegistrationPage from './RegistrationPage';

/* Global config for Firebase access */
const config = {
  apiKey: "AIzaSyAPCQsqhmAmOmkU3FxiA2RXCdL2Cb9v8qY",
  authDomain: "data-crowdsourcing-platform.firebaseapp.com",
  databaseURL: "https://data-crowdsourcing-platform.firebaseio.com",
  projectId: "data-crowdsourcing-platform",
  storageBucket: "data-crowdsourcing-platform.appspot.com",
  messagingSenderId: "1024411944097",
  appId: "1:1024411944097:web:77cda278a5cb0ae2"
}
firebase.initializeApp(config);

class App extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      /* UI */
      showSignIn: true,
      showJobList: false,
      showStartMessage: false,
      showJob: false,
      showRegistration: false,
      disableReturnHomeButton: false,
      disabeJobListButtons: false,

      /* Jobs Control */
      finishedJobs: [],
      availableJobs: [],
      completedJobs: [],
      ownedJobs: [],


      /* Job Completion*/
      selectedJob: "",
      jobQuestions: {},
      jobQuestionIds: [],
      jobAnswers: {},
      //jobAnswerUrls: {}
    }


    this.refreshJobsAvailable = this.refreshJobsAvailable.bind(this);
    this.signOut = this.signOut.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.cancelStart = this.cancelStart.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.returnHome = this.returnHome.bind(this);
    this.startJob = this.startJob.bind(this);
    this.updateJobAnswers = this.updateJobAnswers.bind(this);
    //this.updateJobAnswerUrls = this.updateJobAnswerUrls.bind(this);
    this.markJobCompleted = this.markJobCompleted.bind(this);
    this.finishRegistration = this.finishRegistration.bind(this);
  
  }

  componentDidMount(){
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          if(user){
            let db = firebase.firestore();
            db.collection("Users").doc(firebase.auth().currentUser.uid).get().then(async (doc)=>{
              if(doc.exists){
                this.refreshJobsAvailable();
                let result = await db.collection("Answers").doc(firebase.auth().currentUser.uid).get();
                if(!result.exists){
                  db.collection("Answers").doc(firebase.auth().currentUser.uid).set({}, {merge: true});
                }
                this.setState({
                  showSignIn: false,
                  showJobList: true
                });
              }else{
                this.setState({
                  showSignIn: false,
                  showJobList: false,
                  showRegistration: true
                });
              }
            });
            
            
          }else{
            this.setState({
              showSignIn: true,
              showJobList: false
            })
          }
        }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  getDescription(){
    for(let job of this.state.availableJobs){
      if(job.id === this.state.selectedJob){
        return job.description;
      }
    }
    return "";
  }

  async refreshJobsAvailable(){
    let db = firebase.firestore();
    let query;

    // Get user's finished jobs
    query = await db.collection("Users").doc(firebase.auth().currentUser.uid).get();
    let finishedJobs = (query.data()['CompletedJobs'])?query.data()['CompletedJobs']:[];

    // Get user's owned jobs
    let availableJobs = [];
    let completedJobs = [];
    let ownedJobs = [];
    query = await db.collection("Jobs").where("Assignees", "array-contains", firebase.auth().currentUser.uid).get();
    let docs = query.docs;
    for(let doc of docs){
      let id = doc.id;
      let data = doc.data();
      ownedJobs.push(id);

      if(finishedJobs.indexOf(id) === -1){
        availableJobs.push({
          id: id,
          name: data['Name'],
          questionCount: Object.keys(data['Questions']).length,
          isDeleted: data['IsDeleted']? data['IsDeleted']:false,
          description: data['Description']? data['Description']:""
        })
      }else{
        completedJobs.push({
          id: id,
          name: data['Name']
        })
      }
    }

    query = await db.collection("Answers").doc(firebase.auth().currentUser.uid).get();
    if(query.exists){
      let data = query.data();
      for(let i=0; i<availableJobs.length; i++){
        if(!data[availableJobs[i]['id']]){
          availableJobs[i]['finishedQuestionCount'] = 0;
        }else{
          availableJobs[i]['finishedQuestionCount'] = Object.keys(data[availableJobs[i]['id']]).length;
        }
      }
    }else{
      for(let i=0; i<availableJobs.length; i++){
        availableJobs[i]['finishedQuestionCount'] = 0;
      }
    }
    

    this.setState({
      finishedJobs: finishedJobs,
      ownedJobs: ownedJobs,
      availableJobs: availableJobs,
      completedJobs: completedJobs
    });
  }

  signOut() {
    firebase.auth().signOut();
    this.setState({
      showJobList: false,
      showSignIn: true
    })
  }

  selectJob(id) {
    this.setState({
      selectedJob: id,
      showStartMessage: true
    });
  }

  async startJob(){
    this.setState({
      disabeJobListButtons: true
    });
    let db = firebase.firestore();
    let query;

    query = await db.collection("Jobs").doc(this.state.selectedJob).get();
    if(query.data()['IsDeleted'] && query.data()['IsDelted'] === true){
      alert("This job has been removed by admin.");
      this.setState({
        selectedJob: "",
        showStartMessage: false
      });
      this.refreshJobsAvailable();
      return;
    }
    let jobQuestions = Object.assign({}, query.data()['Questions']);
    let jobQuestionIds = Object.keys(jobQuestions);

    query = await db.collection("Answers").doc(firebase.auth().currentUser.uid).get();
    let jobAnswers = {};
    //let jobAnswerUrls = {};
    if(query.exists && query.data()[this.state.selectedJob]){
      let data = query.data();
      jobAnswers = data[this.state.selectedJob];
    }

    /*
    for(let questionId of Object.keys(jobAnswers)){
      if(jobAnswers[questionId]['Type'] == "recording"){
        let ref = firebase.storage().ref(jobAnswers[questionId]['Answer'][0]);
        jobAnswerUrls[questionId] = await ref.getDownloadURL();
      }
    }
    */



    this.setState({
      showStartMessage: false,
      showJobList: false,
      showJob: true,
      jobAnswers: jobAnswers,
      jobQuestions: jobQuestions,
      jobQuestionIds: jobQuestionIds,
      //jobAnswerUrls: jobAnswerUrls,
      disabeJobListButtons: false
    });
  }

  cancelStart(){
    this.setState({
      selectedJob: "",
      showStartMessage: false,
      disabeJobListButtons: false
    });
  }

  async finishRegistration(){
    await this.refreshJobsAvailable();
    this.setState({
      showRegistration: false,
      showJobList: true
    });
  }

  async returnHome(){
    this.setState({
      disableReturnHomeButton: true
    });
    await this.refreshJobsAvailable();
    this.setState({
      showJobList: true,
      showStartMessage: false,
      showJob: false,
      disableReturnHomeButton: false,

      selectedJob: "",
      jobQuestions: {},
      jobQuestionIds: [],
      currentQuestionIndex: 0,
      jobAnswers: {},
      //jobAnswerUrls: {}
    });
  }

  updateJobAnswers(jobAnswers){
    this.setState({
      jobAnswers: jobAnswers,
    });
  }

  /*
  updateJobAnswerUrls(jobAnswerUrls){
    this.setState({
      jobAnswerUrls: jobAnswerUrls,
    });
  }
  */

  async markJobCompleted(){
    let jobId = this.state.selectedJob;
    if(this.state.finishedJobs.indexOf(jobId) === -1){
      let finishedJobs = this.state.finishedJobs;
      finishedJobs.push(jobId);
      let db = firebase.firestore();
      await db.collection("Users").doc(firebase.auth().currentUser.uid).set({CompletedJobs: finishedJobs}, {merge: true});
      this.setState({
        finishedJobs: finishedJobs
      });
    }
  }

  render(){
  
    if(this.state.showSignIn){
      return(
        <SignInPage 
          firebase={firebase}
        />
      )
    }

    if(this.state.showRegistration){
      return(
        <RegistrationPage 
          firebase={firebase}
          finishRegistration={this.finishRegistration}
        />
      )
    }

    if(this.state.showJobList){
      return(
        <JobListPage
          firebase={firebase}
          showStartMessage={this.state.showStartMessage}
          availableJobs={this.state.availableJobs}
          completedJobs={this.state.completedJobs}
          signOut={this.signOut}
          selectJob={this.selectJob}
          startJob={this.startJob}
          cancelStart={this.cancelStart}
          getDescription={this.getDescription}
          disabeJobListButtons={this.state.disabeJobListButtons}
        />
      )
    }

    if(this.state.showJob){
      return(
        <JobPage
          firebase={firebase}
          selectedJob={this.state.selectedJob}
          jobAnswers={this.state.jobAnswers}
          //jobAnswerUrls={this.state.jobAnswerUrls}
          jobQuestions={this.state.jobQuestions}
          jobQuestionIds={this.state.jobQuestionIds}
          updateJobAnswers={this.updateJobAnswers}
          //updateJobAnswerUrls={this.updateJobAnswerUrls}
          returnHome={this.returnHome}
          markJobCompleted={this.markJobCompleted}
          disableReturnHomeButton={this.state.disableReturnHomeButton}
        />
      )
    }
  }
}


export default App;
