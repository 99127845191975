import React from 'react';
import os from 'os';

/* Material UI components */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { green } from '@material-ui/core/colors';
import { Avatar, Button, CssBaseline, Dialog, Typography, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

/* Styling */
const MainWindow = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const LogoAvatar = styled(Avatar)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}));

const StartJobButton = styled(PlayCircleOutlineOutlinedIcon)(({theme}) => ({
    color: green[400],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
}));

const RootDiv = styled('div')(({theme}) => ({
    width: '100%',
}));

const JobListTable = styled(Table)(({theme}) => ({
    midWidth: 400
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2)
}));

  
class JobListPage extends React.Component{
    render(){
      let jobDescription = this.props.getDescription();
      jobDescription = jobDescription.replace(/\\n/g, os.EOL);
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <MainWindow>
                    <LogoAvatar>
                    <LockOutlinedIcon />
                    </LogoAvatar>
                    <Typography align="center" component="h1" variant="h5">
                    Hi, {this.props.firebase.auth().currentUser.displayName}
                    </Typography>
                    <Typography align="center" component="h1" variant="h4">
                    List of Jobs
                    </Typography>
                    <JobTable 
                      availableJobs={this.props.availableJobs} 
                      completedJobs={this.props.completedJobs}
                      selectJob={this.props.selectJob}
                      disableJobListButtons={this.props.disableJobListButtons}
                    />
                    <Button variant="contained" color="secondary" onClick={this.props.signOut}>Logout</Button>
                </MainWindow>
                  <Dialog
                      open={this.props.showStartMessage}
                      fullWidth={true}
                      //onClose={this.props.cancelStart}
                      disableBackdropClick={true}
                      disableEscapeKeyDown={true}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                  >
                    
                      <DialogTitle id="alert-dialog-slide-title">Are you going to start the job?</DialogTitle>
                      <DialogContent>
                        <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}> 
                          <DialogContentText id="alert-dialog-slide-description">
                            {jobDescription}
                          </DialogContentText>
                        </pre>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.props.cancelStart} color="secondary" disabled={this.props.disabeJobListButtons}>Cancel</Button>
                        <Button onClick={this.props.startJob} color="primary" disabled={this.props.disabeJobListButtons}>Start</Button>
                      </DialogActions>
                      
                  </Dialog>
            </Container>
            
        )
    }
}

class JobTable extends React.Component{
    render(){
      let rows = [];
      let availableJobs = this.props.availableJobs;
      for(let i=0; i<availableJobs.length; i++){
        if(availableJobs[i].isDeleted){
          rows.push(
            <TableRow key={availableJobs[i].id}>
              <TableCell component="th" scope="row">{availableJobs[i].name}</TableCell>
              <TableCell align="center">Unavailable</TableCell>
              <TableCell align="center">
              </TableCell>
            </TableRow>
          );
        }else{
          rows.push(
            <TableRow key={availableJobs[i].id}>
              <TableCell component="th" scope="row">{availableJobs[i].name}</TableCell>
              <TableCell align="center">{availableJobs[i].finishedQuestionCount} / {availableJobs[i].questionCount}</TableCell>
              <TableCell align="center">
                <StartJobButton 
                  onClick={() => this.props.selectJob(availableJobs[i].id)} disabled={this.props.disableJobListButtons}
                />
              </TableCell>
            </TableRow>
          );
        }
      }
      
      let completedJobs = this.props.completedJobs;
      for(let i=0;i<completedJobs.length; i++){
        rows.push(
          <TableRow key={completedJobs[i]['id']}>
            <TableCell component="th" scope="row">{completedJobs[i]['name']}</TableCell>
            <TableCell align="center">{"Completed"}</TableCell>
            <TableCell align="center">
              
            </TableCell>
          </TableRow>
        );
      }
      
      
      return (
        <RootDiv>
          <StyledPaper>
            <JobListTable size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Progress</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </JobListTable>
          </StyledPaper>
        </RootDiv>
      )
    }
  
  }

export default JobListPage;