import React from 'react';

/* Firebase and Firebase Auth */
import StyledFirebaseAuth from 'react-firebaseui/FirebaseAuth';

/* Material UI components */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Avatar, CssBaseline, Typography, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

/* Styling */
const MainWindow = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const LogoAvatar = styled(Avatar)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}));

class SignInPage extends React.Component{
    constructor(props){
        super(props);
        this.uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
              this.props.firebase.auth.EmailAuthProvider.PROVIDER_ID,
              this.props.firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ],
            callbacks: {
              signInSuccessWithAuthResult: async ({user: {email}}) => {
                const fireStoreInstance = this.props.firebase.firestore().collection("Users").doc(this.props.firebase.auth().currentUser.uid);

                // get user info.
                const res = await fireStoreInstance.get();
                const isUserExist = res.data();

                // if user is an old user & missing an email field
                // (new user will have an email field after registration)
                if(isUserExist && !isUserExist.Email)
                    await this.props.firebase.firestore().collection("Users").doc(this.props.firebase.auth().currentUser.uid).set({...res.data(), Email: email});

                return false;
              },
            }
        };

    }

    render(){
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <MainWindow>
                <LogoAvatar>
                    <LockOutlinedIcon />
                </LogoAvatar>
                <Typography align="center" component="h1" variant="h5">
                    Sign in
                </Typography>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={this.props.firebase.auth()}/>
                </MainWindow>
            </Container>
        )
    }
}

export default SignInPage;